import React from "react";
import { Typography, makeStyles, Button, Menu, MenuItem, styled, alpha } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import partnerLogoImg from "../../images/Target_Parnters_Logo.png";
import { useHistory } from "react-router-dom";
import { Link as RLink } from "react-router-dom";
import { KeyboardArrowDown } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: '50px',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  headerButtonGroup: {
    boxShadow: 'none',
  },
  headerButton:{
    borderRadius: '20px',
    background: '#CC0000',
    color: "#FFFFFF",
    textTransform: 'capitalize',
    fontSize: '13.33px',
    borderColor: '#FFFFFF !important',
    '&:hover': {
      background: '#CC0000',
      color: "#FFFFFF",
      boxShadow: 'none',
    }
  },

  button: {
    borderRadius: 20,
    textTransform: "none",
    borderColor: "#CC0000",
    color: "#CC0000",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:first-child": {
      marginRight: 30,
    },
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        marginRight: 10,
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
     paddingBottom: theme.spacing(0.5),

    },
    "&:hover": {
        background: "none",
      },
  },

  buttonActive: {
    borderRadius: 20,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textTransform: "none",
    boxShadow: "none !important",
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.6),
        paddingBottom: theme.spacing(0.6),
  
      },
    "&:hover": {
        background: "#CC0000",
      },
  },

  partnerLogo: {
    verticalAlign: "middle",
    cursor: "pointer",
    width: 200,
    [theme.breakpoints.up("lg")]: {
      width: 200,
    },
    [theme.breakpoints.up("xl")]: {
      width: 200,
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  logoLink: {
    "&:focus": {
      outline: "none",
    },
  },
  skipNav: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "fixed",
    width: 1,
    "&:focus": {
      background: "white",
      clip: "auto",
      height: "auto",
      left: 0,
      top: 0,
      width: "auto",
      margin: 0,
      zIndex: 1500,
      outline: 0,
      textDecoration: "underline",
      border: "1px dashed #d3d3d3",
      padding: 3,
    },
  },
  toolBar: {
    height: 90,
    flexWrap: 'wrap',
    [theme.breakpoints.down("sm")]: {
      // height: 50,
      justifyContent: 'center'
    },
  },
  header: {
    boxShadow: "none !important",
  },
  nav: {
    flex: '1 0 0',
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'space-between',
    },
  },
  navButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    paddingRight: '50px',
    [theme.breakpoints.down("sm")]: {
      // display: 'none',
      justifyContent: 'space-around',
      paddingRight: 0,
    },
  },
  mainMenuBlock: {
    display: "none",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      display: "inline-block",
    },
  },
  menuUL: {
    padding: 0,
  },
  menuItems: {
    color: "#707070",
    fontSize: "0.9rem",
    position: "relative",
    display: "inline-block",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textDecoration: "none",
    "&:focus": {
      outline: "none",
      textDecoration: "underline",
    },
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus:before": {
      content: '""',
      top: -35,
      width: "100%",
      height: "100%",
      position: "absolute",
      borderTop: "4px solid #b5b5b5",
    },
    "&:hover:before": {
      content: '""',
      top: -35,
      width: "100%",
      height: "100%",
      position: "absolute",
      borderTop: "4px solid #b5b5b5",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 8,
    },
  },
  mobMenuItems: {
    color: "#333333",
    fontSize: "1rem",
    position: "relative",
    fontWeight: 500,
    textDecoration: "none",
    display: "block",
    padding: theme.spacing(2),
    "&:focus": {
      outline: "1px dotted",
    },
  },
  mobSelectedMenu: {
    fontWeight: "bold",
  },
  subMenuItems: {
    color: "#333333",
    textDecoration: "none",
    fontSize: "0.8rem",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:focus": {
      outline: "1px dotted",
      [theme.breakpoints.down("sm")]: {
        padding: 5,
      },
    },
    "&:hover": {
      textDecoration: "underline",
      color: "#cc0000",
    },
  },


  "@keyframes showArrow": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  

}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const RenderMenu = (props) => {
  
  const { title, menuItems } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={`${title}-customized-button`}
        aria-controls={open ? `${title}-customized-button` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        className={classes.headerButton}
        size="large"
      >
        {title}
      </Button>
      <StyledMenu
        id={`${title}-customized-button`}
        MenuListProps={{
          'aria-labelledby': `${title}-customized-button`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map( menuItem => 
          <MenuItem
            key={`${title}-${menuItem.title}`}
            onClick={() => {
              window.open(menuItem.slug, '_blank');
              handleClose();
            }}
            disableRipple
          >
            {menuItem.title}
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export const SkipNavLink = ({
  id,
  classes,
  children = "Skip to main content",
  ...props
}) => {
  return (
    <a {...props} href={`#${id}`} className={classes.skipNav}>
      {children}
    </a>
  );
};

export default function HeaderSection(props) {
  const { menu } = props;
  const history = useHistory();
  const classes = useStyles();
  
  const gotoHome = () => {
    history.push("/");
  };

  return (
    <>
      <SkipNavLink id="main-content" classes={classes}>
        Skip to main content
      </SkipNavLink>
      <SkipNavLink id="footer" classes={classes}>
        Skip to footer
      </SkipNavLink>
      <AppBar position="fixed" className={classes.header}>
        <Toolbar className={classes.toolBar}>
          <Typography className={classes.title} component="h1">
            <RLink to="/" className={classes.logoLink}>
              <img
                onClick={gotoHome}
                className={classes.partnerLogo}
                src={partnerLogoImg}
                alt={"Partner Logo"}
              />
            </RLink>
          </Typography>
          <nav className={classes.nav}>
            <div className={classes.navButtonsContainer}>
              {menu && menu.map( ({title, items: menuItems}) => 
                <RenderMenu key={title} title={title} menuItems={menuItems} />
              )}
            </div>
          </nav>
        </Toolbar>
      </AppBar>
    </>
  );
}
